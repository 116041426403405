// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
	--ion-color-primary: #2877cc;
	--ion-color-primary-rgb: 40,119,204;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #2369b4;
	--ion-color-primary-tint: #3e85d1;

	--ion-color-secondary: #ff6701;
	--ion-color-secondary-rgb: 235,94,0;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #cf5300;
	--ion-color-secondary-tint: #ed6e1a;

	--ion-color-tertiary: #3880ff;
	--ion-color-tertiary-rgb: 56,128,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #3171e0;
	--ion-color-tertiary-tint: #4c8dff;

	--ion-color-success: #29ad5d;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #20904d;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #d1a100;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #ec4646;
	--ion-color-danger-rgb: 236,70,70;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #d03e3e;
	--ion-color-danger-tint: #ee5959;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

  --casa-text-color-h1: #0a0a0a;
  --casa-text-color-h2: #323232;
  --casa-text-color-h4: #6c6c6c;

  --casa-color-active: #f6f6ae;
  --casa-bg-gray: #f9fafb;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.text-h1 { color: var(--casa-text-color-h1)}
.text-h2 { color: var(--casa-text-color-h2)}
.text-h4 { color: var(--casa-text-color-h4)}

.text-color-primary {color: var(--ion-color-primary)}
.text-color-danger {color: var(--ion-color-danger)}
.text-color-success {color: var(--ion-color-success)}
.text-color-secondary {color: var(--ion-color-secondary)}
.text-color-tertiary {color: var(--ion-color-tertiary)}
.text-color-medium {color: var(--ion-color-medium)}
.text-color-warning {color: var(--ion-color-warning)}

.icon-xl {font-size: 30px}
.icon-lg {font-size: 20px}

.bg-white {background-color: white;}
.bg-gray {background-color: var(--casa-bg-gray)}
.color-white {color: white;}

.pointer.text-h1:hover {color: var(--ion-color-primary)}

.casago-gradient {
  background: linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%);

  &.opacity-3 {
    opacity: .3;
  }
}

.flex-container {display: flex}
.flex-wrap {flex-wrap: wrap}
.flex-center {align-items: center}
.flex-middle {justify-content: center}
.flex-col {flex-direction: column}
.flex-row {flex-direction: row}
.flex-item-same-width {flex: 1 1 0; width: 0}
.grid-container {display: grid}
.-fg1 {flex-grow: 1}
.-fg2 {flex-grow: 2}
.-fg3 {flex-grow: 3}
.-flex1 {flex: 1}
.-flex2 {flex: 2}
.col-gap-4 {column-gap: 4px}
.col-gap-8 {column-gap: 8px}
.col-gap-12 {column-gap: 12px}
.col-gap-20 {column-gap: 20px}
.row-gap-4 {row-gap: 4px}
.row-gap-8 {row-gap: 8px}
.row-gap-12 {row-gap: 12px}

.fullscreen{width: 100%;height: 100%;position: absolute;left: 0px;top: 0px;}
.fixed{position: fixed;}
.overflow-scroll {overflow: scroll}
.overflow-hidden {overflow: hidden !important}
.overflow-unset {overflow: unset !important}
.max-h100 {max-height: 100%;}

.rel-m-2 {z-index: -2}
.rel-m-1 {z-index: -1}
.rel-m-0 {z-index: -0}

.margin-auto {margin: auto}
.nomargin {margin: 0px !important}
.-m15 {margin: 15px}
.-mt0 {margin-top: 0px}
.-mt8 {margin-top: 8px}
.-mt10 {margin-top: 10px}
.-mt12 {margin-top: 12px}
.-mt18 {margin-top: 18px}
.-mt20 {margin-top: 20px}
.-mt20i {margin-top: 20px !important}
.-mt24 {margin-top: 24px}
.-mt30 {margin-top: 30px}
.-mr8 {margin-right: 8px}
.-ml8 {margin-left: 8px}
.-ml12 {margin-left: 12px}
.-ml12i {margin-left: 12px !important}
.-mlauto {margin-left: auto};
.-mb8 {margin-bottom: 8px}
.-mb12 {margin-bottom: 12px}
.-mb16 {margin-bottom: 16px}
.-mb12i {margin-bottom: 12px !important}
.-mb20 {margin-bottom: 20px}
.-mb30 {margin-bottom: 30px}

.nopadding {padding: 0px !important}
.-pd4 {padding: 4px}
.-pd8 {padding: 8px}
.-pd12 {padding: 12px}
.-pd20 {padding: 20px}
.-pd25 {padding: 25px}
.-pr4 {padding-right: 4px}
.-pr8 {padding-right: 8px}
.-pr15 {padding-right: 15px}
.-pr20 {padding-right: 20px}
.-pr25 {padding-right: 25px}
.-pt0 {padding-top: 0px !important}
.-pt8 {padding-top: 8px}
.-pt12 {padding-top: 12px}
.-pt20 {padding-top: 20px}
.-pb4 {padding-bottom: 4px}
.-pb8 {padding-bottom: 8px}
.-pb20 {padding-bottom: 20px}
.-pb30 {padding-bottom: 30px !important}
.-pl0 {padding-left: 0px !important}
.-pl4 {padding-left: 4px}
.-pl8 {padding-left: 8px}
.-pl12 {padding-left: 12px}
.-pl15 {padding-left: 15px}
.-pl20 {padding-left: 20px}
.-pl25 {padding-left: 25px}
.-plr8 {padding-left: 8px; padding-right: 8px}
.-plr20 {padding-left: 20px; padding-right: 20px}

.-borderb {border-bottom: 1px solid #e1e1e1}

.text-center{text-align: center !important;}
.text-right{text-align: right !important}
.text-left{text-align: left;}

.text-muted {color: var(--casa-text-color-h4)}
.text-notransform {text-transform: none !important;}
.text-bold {font-weight: 800}
.lineh {line-height: 1.6}
.capitalize {text-transform: capitalize;}
.uppercase {text-transform: uppercase;}
.text-sm {font-size: 14px}
.text-xl {font-size: 24px}

.width100 {width: 100%}
.width70 {width: 70%}
.width65 {width: 65%}
.height100 {height: 100%}

.max-w120 {max-width: 120px;}

.block {display: block;}
.inline-block {display: inline-block;}
.pull-right {float: right;}

.no-wrap {
  text-wrap: nowrap;
  overflow: hidden;
}

.badge-inactive {
  display: inline-block;
  background-color: #ffb5b5;
  font-size: 14px;
  padding: 2px 6px;
  margin-left: 4px;
}

hr.hr {
  background-color: #e1e1e1;
}

h3.section-divide {
  font-size: 22px;
  font-weight: 300;
  color: var(--casa-text-color-h4);
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 8px;
}

.table {

  &.padding {
    th, td {
      padding: 4px 4px;
    }
  }

  th {
    font-weight: 500;
  }
  thead {
    tr {
      background-color: #f8f9fa;
    }
  }
}
.table-bordered {
  th, td {
    border: 1px solid #cacaca;
  }
}

.custom-form-label {
  color: var(--ion-color-primary);
  margin-top: 0px;
}

.custom-form-label.w-buttons.sticky {
  position: sticky;
  top: -20px;
  background-color: white;
  z-index: 1;
  padding: 8px 4px;
  border: 1px solid #e1e1e1;
}

.custom-form-label.w-buttons {
  display: flex;
  align-items: center;

  .btns-wrapper {
    margin-left: auto;
  }

  ion-button {
    margin: 0px;

  }
}

.input-fillout {
  display: inline-block;
  background-color: white;
  width: 130px;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  word-break: break-all;
  overflow: hidden;
  margin: 2px 6px;
  text-align: center;
  white-space: nowrap;
}

ion-button.no-shadow {
  --box-shadow: none;
}

ion-button.rounded {
  --border-radius: 15px;
}

ion-button.-casa-button-icon {
  height: 40px;
  letter-spacing: 1px;
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 6px;
  --padding-bottom: 6px;
  --border-radius: 30px;
  --box-shadow: none;
}

.-casa-loading {
  --spinner-color: var(--ion-color-secondary);
}
.casa-invalid-input {
  border-color: var(--ion-color-danger) !important;
}
.-section-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    color: var(--casa-text-color-h1);
    display: flex;
    align-items: center;
    margin: 0px;
  }
  ion-icon, fa-icon {
    padding-right: 8px;
    color: var(--ion-color-primary)
  }
  ion-button {
    margin-left: auto;
    --border-radius: 20px;
    --box-shadow: none;
    --padding-start: 30px;
    --padding-end: 30px;
    text-transform: none;
  }
}
.-section-action {
  display: flex;
  border-top: 1px solid #e1e1e1;
  padding-top: 8px;
  margin-top: 8px;

  &.no-line {
    border: none;
  }

  ion-button.submit {
    margin-left: auto;
    --border-radius: 20px;
    --box-shadow: none;
    --padding-start: 30px;
    --padding-end: 30px;
    text-transform: none;
  }
}
ion-button.-casa-btn {
  margin-left: auto;
  --border-radius: 20px;
  --box-shadow: none;
  --padding-start: 30px;
  --padding-end: 30px;
  text-transform: none;
}
ion-button.-casa-btn-default {
  --border-radius: 20px;
  --box-shadow: none;
  --padding-start: 30px;
  --padding-end: 30px;
  text-transform: none;
}
ion-button.-casa-btn-sub {
  margin-left: auto;
  --border-radius: 20px;
  --box-shadow: none;
  --padding-start: 15px;
  --padding-end: 15px;
  height: 25px;
  text-transform: none;
}
ion-button.-casa-btn-favicon {
  --padding-start: 4px !important;
  --padding-end: 4px !important;
  fa-icon {
    font-size: 22px;
  }
}
ion-button[fill="clear"] {
  --padding-start: 4px;
  --padding-end: 4px;
}
ion-item.input-item {
  --inner-padding-start: 16px;
  --padding-start: 0px;

  ion-label {
    color: var(--casa-text-color-h4) !important;
  }
}
.underline {
  text-decoration: underline;
}
.-fixed-title {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 8px;
  align-items: center;

  &.-breadcrumbs {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
  }

  &.-filters-wrapper {
    padding: 2px 10px 10px 10px;
  }

  h1 {
    ion-icon, fa-icon {
      color: var(--ion-color-primary);
    }

    fa-icon {
      padding-right: 8px;
    }
  }

  h2 {
    color: var(--casa-text-color-h2);
    display: flex;
    align-items: center;
    font-size: 19px;
    padding-bottom: 8px;
    padding-left: 8px;
    margin-top: 10px;

    i.pi {font-size: 20px;padding-right: 4px;}
  }

  .-subtitle {
    padding-left: 12px;
    padding-bottom: 8px;
    color: #515151;
    font-size: 14px;
  }

  ion-button.submit {
    margin-left: auto;
    --border-radius: 20px;
    --box-shadow: none;
    --padding-start: 30px;
    --padding-end: 30px;
    text-transform: none;
  }

  ion-button.sub-icon-only {
    margin-left: auto;
  }
}

.-modal-header-fixed {
  overflow: scroll;

  ._header {
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 8px;
    align-items: center;
    position: fixed;
    top: 0px;
    background: white;
    z-index: 2;
    width: 100%;
    max-height: 92px;
    overflow: hidden;

    h2 {
      color: var(--casa-text-color-h2);
      display: flex;
      align-items: center;
      font-size: 19px;
      padding-bottom: 8px;
      padding-left: 8px;

      i.pi {font-size: 20px;padding-right: 4px;}
    }

    ion-label {
      padding-left: 8px;

      h2, p {
        padding-left: 0;
        padding-bottom: 0;
      }
    }

    ion-button.-left-action, ion-button.-right-action {
      height: 40px;
      letter-spacing: 1px;
      --padding-start: 8px;
      --padding-end: 8px;
      --padding-top: 6px;
      --padding-bottom: 6px;
      --border-radius: 30px;
      --box-shadow: none;
    }
    ion-button.submit {
      margin-left: auto;
      --border-radius: 20px;
      --box-shadow: none;
      --padding-start: 30px;
      --padding-end: 30px;
      text-transform: none;
    }
    .right-action {
      margin-left: auto;

      mat-form-field  {
        height: 55px;

        .mat-mdc-text-field-wrapper {
          border:2px solid var(--ion-color-primary);
        }

        .mat-mdc-form-field-subscript-wrapper {
          display: none;
        }

        mat-select {
          .mat-mdc-select-value-text {
            color: var(--ion-color-primary);
          }
        }
      }
    }
  }

  ._content {
    margin-top: 92px;
    position: relative;
    z-index: 1;
  }

  ._content.wlabel {
    margin-top: 90px;
  }
}
.-bordertop {border-top: 1px solid #cacaca;}
.pointer {cursor: pointer;}
a.sub-link {cursor: pointer;width: 100%;color: #575757;text-decoration: none;}
a.sub-link:hover {color: var(--ion-color-primary)}

.alert-button-role-destructive {
  color: var(--ion-color-danger) !important;
}
.alert-button-role-cancel {
  color: var(--ion-color-medium) !important;
}

ion-modal.modalsm {
  --max-width: 500px;
}

ion-modal.modalmd {
  --width: 70%;
}

ion-modal.modalh {
  --min-height: 80%;
}

ion-modal.modalxh {
  --min-height: 90%;
}

ion-modal.modallg {
  --width: 80%;
  --min-height: 80%;
}

ion-modal.modal-vfull {
  --width: 80%;
  --min-height: 100%;
}

ion-modal.modal-max-800 {
  --max-width: 800px;
}

ion-modal.modal-max-1000 {
  --max-width: 1000px;
}

ion-modal.modal-max-1200 {
  --max-width: 1200px;
}

ion-modal.modalxl {
  --width: 90%;
  --min-height: 90%;
}

ion-modal.modalh-auto {
  --height: auto;
  --max-height: 90%;
}

ion-modal.modalw-auto {
  --width: auto;
  --min-width: 500px;
}

ion-modal.modal-fullscreen {
  --width: 100%;
  --min-height: 100%;
}

ion-modal.modal-mobile {
  --height: 100%;
  --max-height: 85%;
}

ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
}

mat-hint {
  color: var(--casa-text-color-h4);
  white-space: nowrap;
}
router-outlet {display: none;}

.tooltip-content{
  ul {
    font-size: 14px;
    margin: 0px;
    padding-left: 12px;

    li b {
      font-weight: 500;
    }
  }
}

.action-option {
  min-width: 155px;

  &.link {
    display: block;
    padding: 4px 12px;
    text-decoration: none;
    color: var(--casa-text-color-h2);

    fa-icon {
      color: var(--ion-color-primary);
    }
  }

  &.danger span{
    color: var(--ion-color-danger);
  }

  &.primary span{
    color: var(--ion-color-primary);
  }

  &.dark span{
    color: var(--ion-color-dark);
  }

  .mdc-list-item__primary-text {
    display: inline-block;
    width: 100%;
  }
}

fa-icon.action-option-icon {
  padding-left: 12px;
  float: right;
  font-size: 25px;
  color: black;

  &.left-icon {
    padding-right: 12px;
    padding-left: 0px;
    float: none;
  }

  &.icon-sm {
    font-size: 18px;
  }

  &.success {
    color: green;
  }

  &.primary {
    color: #007cff;
  }

  &.danger {
    color: red;
  }

  &.warning {
    color: var(--ion-color-warning);
  }
}

ion-tab-button {
  fa-icon {
    order: -1;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 20px;
  }
}

.max-w-full {
  max-width: 100%!important;
}

.hidden {
  display: none !important;
}

.overlay-no-padding {
  > div {
    padding: 0px !important;
  }
}

.overlay-actions {
  padding: 4px;
  text-align: right;

  ion-button {
    font-size: 12px;
  }

  .left {
    float: left;
  }
}
.overlay-noarrow::before, .overlay-noarrow::after {
  content: none !important;
}

.overlay-left-300 {
  transform: translate(-300px, 0px) !important;
}

.overlay-left-290 {
  transform: translate(-290px, 0px) !important;
}

.overlay-x340-y170 {
  transform: translate(-340px, -170px) !important;
}

.overlay-x350-y190 {
  transform: translate(-350px, -190px) !important;
}

.overlay-mh250 {
  .p-listbox-list {
    max-height: 270px;
  }
}

@media only screen and (max-width: 1370px) {
  ion-modal.modal-tablet-full {
    --width: 100%;
    --min-height: 100%;
  }
}
@media only screen and (max-width: 500px) {
  ion-modal.modalw-auto {
    --min-width: 100%;
  }
}
// #login-form {
//     .mat-form-field {
//         width: 100%
//     }
//     .mat-form-field-flex {
//         border-radius: 30px !important;
//         border: 1px solid #ccc !important;
//         background-color: white;
//     }
//     .mat-form-field-infix {
//         border-top: none;
//     }
//     .mat-form-field-underline {
//         width: 88%;
//         margin-left: 6%;
//     }
//     input {
//         height: 20px;
//         padding: 0px 2px;
//     }
// }

// .p-inline-message .p-inline-message-icon, .p-inline-message .p-inline-message-text {
//     font-size: 1.3rem;
// }

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66,140,255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }
